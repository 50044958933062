<template>
  <div>
    <v-container class="py-6">
      <v-row class="row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
        <v-col v-for="(shop, i) in allShops" :key="i">
          <shop-box :shop-details="shop" :is-loading="loading" />
        </v-col>
      </v-row>

      <p v-if="!loading && !allShops.length" class="pa-4 text-center fs-20">
        {{ $t('not_find_shop') }}
      </p>

      <div
        class="d-flex justify-center"
        v-if="totalPages > 1 && allShops.length"
        style="align-items: center; flex-wrap: wrap"
      >
        <v-pagination
          v-model="page"
          class="my-4"
          :length="totalPages"
          prev-icon="la-angle-left"
          next-icon="la-angle-right"
          :total-visible="7"
          elevation="0"
          @input="pageSwitch"
        ></v-pagination>
        <input
          v-model="searchPage"
          :placeholder="page"
          type="number"
          :name="$t('payment_option')"
          style="
            border: 1px solid #ececec;
            height: 32px;
            width: 60px;
            text-align: center;
            margin-right: 10px;
            border-radius: 4px;
          "
        />
        <v-btn color="primary" small @click.stop.prevent="onSearchPage">
          {{ $t('search') }}
        </v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
import ShopBox from '@/components/shop/ShopBox.vue'

export default {
  data() {
    return {
      loading: true,
      totalPages: 1,
      allShops: [
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {}
      ],
      page: 1,
      searchPage: ''
    }
  },

  components: {
    ShopBox
  },

  created() {
    this.pageSwitch(1)
  },

  methods: {
    onSearchPage() {
      this.pageSwitch(this.searchPage)
    },

    async getShops(obj) {
      this.loading = true
      const params = { page: this.page, ...obj }

      const url = `user/follow/list?page=${params.page}`

      const res = await this.call_api('get', url)

      console.log('resss', res)
      if (res.data.success) {
        this.allShops = res.data.data
        this.totalPages = res.data.meta.last_page
        this.page = Number(res.data.meta.current_page)
        this.loading = false
      }
    },

    pageSwitch(pageNumber) {
      this.getShops({ page: pageNumber })
    }
  }
}
</script>
