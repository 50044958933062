var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    _vm.boxStyle == 'two'
      ? 'shop-box-two'
      : _vm.boxStyle == 'three'
      ? 'shop-box-three'
      : _vm.boxStyle == 'four'
      ? 'shop-box-four'
      : 'shop-box-one'
  ]},[(_vm.isLoading && _vm.is_empty_obj(_vm.shopDetails))?_c('div',[_c('v-skeleton-loader',{attrs:{"type":"image","height":"310"}})],1):_c('div',{staticClass:"border rounded overflow-hidden"},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"minw-0 position-relative",attrs:{"sm":_vm.boxStyle == 'three' ? '6' : null,"cols":"12"}},[(_vm.boxStyle != 'three')?_c('div',{staticClass:"lh-0 position-relative"},[_c('router-link',{staticClass:"text-reset d-block",attrs:{"to":{ name: 'ShopDetails', params: { slug: _vm.shopDetails.slug } }}},[_c('img',{staticClass:"img-fit h-150px",attrs:{"src":_vm.addResourceImage(_vm.shopDetails.banner),"alt":_vm.shopDetails.name},on:{"error":function($event){return _vm.imageFallback($event)}}})]),(_vm.boxStyle == 'two')?_c('div',{staticClass:"absolute-bottom-left w-100 grey darken-3 white--text d-flex align-center py-2 fs-12 px-3"},[_c('span',{staticClass:"me-1 fw-600"},[_vm._v(" "+_vm._s(_vm.shopDetails.rating.toFixed(2))+" ")]),_c('v-rating',{staticClass:"lh-1-2",attrs:{"background-color":"","empty-icon":"las la-star","full-icon":"las la-star active","half-icon":"las la-star half","hover":"","half-increments":"","readonly":"","size":"11","length":"5","value":_vm.shopDetails.rating}})],1):_vm._e()],1):_vm._e(),_c('div',{class:[
            'text-center fs-12',
            _vm.boxStyle == 'three'
              ? 'pa-4'
              : _vm.boxStyle == 'four'
              ? 'absolute-left-center align-center d-flex ms-4'
              : 'pa-5 position-relative'
          ]},[(_vm.boxStyle != 'two')?_c('router-link',{staticClass:"text-reset",attrs:{"to":{ name: 'ShopDetails', params: { slug: _vm.shopDetails.slug } }}},[_c('img',{class:[
                'border rounded-circle shadow-2xl border-2 size-90px',
                { 'mt-n15': _vm.boxStyle == 'one' },
                { 'mb-2': _vm.boxStyle != 'four' }
              ],attrs:{"src":_vm.addResourceImage(_vm.shopDetails.logo),"alt":_vm.shopDetails.name},on:{"error":function($event){return _vm.imageFallback($event)}}})]):_vm._e(),_c('div',{class:[
              {
                'ms-3 pt-1 pb-2 px-3 text-start position-relative':
                  _vm.boxStyle == 'four'
              }
            ]},[(_vm.boxStyle == 'four')?_c('div',{staticClass:"white absolute-full opacity-80",staticStyle:{"z-index":"-1"}}):_vm._e(),_c('router-link',{staticClass:"text-reset",attrs:{"to":{
                name: 'ShopDetails',
                params: { slug: _vm.shopDetails.slug }
              }}},[_c('h4',{class:[
                  'fs-21',
                  _vm.boxStyle == 'three'
                    ? 'text-truncate-2 lh-1-4 h-60px'
                    : 'text-truncate',
                  { 'mb-2': _vm.boxStyle != 'four' },
                  { 'opacity-0': !_vm.shopDetails.name }
                ]},[_vm._v(" "+_vm._s(_vm.shopDetails.name || 'null')+" ")])]),(_vm.boxStyle == 'one')?_c('div',{staticClass:"text-truncate-2 opacity-80 h-40px"},_vm._l((_vm.shopDetails.categories.data),function(category,i){return _c('span',{key:i},[_vm._v(" "+_vm._s(category.name)+" "),(_vm.shopDetails.categories.data.length - i != 1)?_c('span',[_vm._v(" , ")]):_vm._e()])}),0):_vm._e(),(_vm.boxStyle != 'two')?_c('div',{class:[
                'd-flex fs-12',
                { 'my-2 justify-center': _vm.boxStyle != 'four' }
              ]},[_c('span',{class:[_vm.boxStyle == 'three' ? '' : 'me-2']},[_vm._v(" "+_vm._s(_vm.shopDetails.rating.toFixed(1))+" ")]),_c('v-rating',{staticClass:"lh-1-4",attrs:{"background-color":"","empty-icon":"las la-star","full-icon":"las la-star active","half-icon":"las la-star half","hover":"","half-increments":"","readonly":"","size":"11","length":"5","value":_vm.shopDetails.rating}})],1):_vm._e(),(_vm.boxStyle == 'one')?_c('div',{staticClass:"opacity-80"},[_vm._v(" "+_vm._s(_vm.$t('total_products') + ' ' + _vm.shopDetails.products_count)+" ")]):_vm._e()],1),(_vm.boxStyle != 'four')?_c('div',{class:[
              _vm.boxStyle == 'two'
                ? 'd-flex flex-column mt-5'
                : _vm.boxStyle == 'three'
                ? 'd-flex flex-column mt-3'
                : 'mt-5'
            ]},[_c('v-btn',{class:[
                _vm.boxStyle == 'one'
                  ? 'ms-4 white--text grey darken-4'
                  : _vm.boxStyle == 'two'
                  ? 'mt-2 bg-soft-primary border border-primary'
                  : _vm.boxStyle == 'three'
                  ? 'mt-2 bg-soft-primary border border-primary'
                  : 'mt-2 bg-soft-primary border border-primary'
              ],attrs:{"elevation":"0","small":_vm.boxStyle == 'one' ? false : true,"to":{
                name: 'ShopDetails',
                params: { slug: _vm.shopDetails.slug }
              }}},[_vm._v(" "+_vm._s(_vm.$t('visit_store'))+" ")])],1):_vm._e()],1)]),(_vm.boxStyle == 'three' || _vm.boxStyle == 'four')?_c('v-col',{staticClass:"minw-0",attrs:{"cols":"12","sm":_vm.boxStyle == 'three' ? '6' : null}},[_c('div',{class:[_vm.boxStyle == 'four' ? 'pa-4' : '']},[(_vm.shopDetails.top_3_products?.data.length)?_c('v-row',{staticClass:"gutters-10",attrs:{"no-gutters":_vm.boxStyle == 'four' ? false : true}},_vm._l((_vm.shopDetails.top_3_products.data),function(product,i){return _c('v-col',{key:i,attrs:{"cols":"12","sm":_vm.boxStyle == 'four' ? '4' : null}},[_c('product-box',{class:[
                  _vm.boxStyle == 'three' && i == 0
                    ? 'my-4 me-4'
                    : _vm.boxStyle == 'three'
                    ? 'mb-4 me-4'
                    : ''
                ],attrs:{"product-details":product,"is-loading":_vm.isLoading,"box-style":"two"}})],1)}),1):_c('div',{staticStyle:{"height":"73px"}}),(_vm.boxStyle == 'four')?_c('div',{staticClass:"text-end mt-3"},[_c('v-btn',{staticClass:"primary--text transparent",attrs:{"small":"","link":"","elevation":"0","to":{
                name: 'ShopDetails',
                params: { slug: _vm.shopDetails.slug }
              }}},[_c('span',[_vm._v(_vm._s(_vm.$t('visit_store')))]),_c('i',{staticClass:"las la-arrow-right"})])],1):_vm._e()],1)]):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }